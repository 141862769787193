export const TRACK_DOWNLOAD_CLASSNAME = 'track-download';
export const TRACK_REFERRAL_CLASSNAME = 'track-referral';

export const DOWNLOAD_ATTR_PROFESSIONAL_TITLE = 'data-professional';
export const DOWNLOAD_ATTR_FILE_TITLE = 'data-file-title';
export const DOWNLOAD_ATTR_FILE_ID = 'data-file-id';
export const DOWNLOAD_ATTR_PROFESSIONAL_ID = 'data-professional-id';
export const DOWNLOAD_ATTR_PRODUCT_ID = 'data-product-id';
export const DOWNLOAD_ATTR_PROJECT_ID = 'data-project-id';

export const REFERRAL_ATTR_PROFESSIONAL_TITLE = 'data-professional';
export const REFERRAL_ATTR_ITEM_ID = 'data-item-id';

export default {
    TRACK_DOWNLOAD_CLASSNAME,
    TRACK_REFERRAL_CLASSNAME,
    DOWNLOAD_ATTR_PROFESSIONAL_TITLE,
    DOWNLOAD_ATTR_FILE_TITLE,
    DOWNLOAD_ATTR_FILE_ID,
    DOWNLOAD_ATTR_PROFESSIONAL_ID,
    DOWNLOAD_ATTR_PRODUCT_ID,
    DOWNLOAD_ATTR_PROJECT_ID,
    REFERRAL_ATTR_PROFESSIONAL_TITLE,
    REFERRAL_ATTR_ITEM_ID,
};
