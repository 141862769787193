import { SnitcherBody } from './Snitcher';
import { FacebookPixelsBody } from './FacebookTracker';
import { GTMBody } from './GTM';
import type { ClientConfig } from '@archipro-website/config';
import { PinterestTrackerBody } from './PinterestTracker';

export const BodyPixels = ({
    gtmId,
    facebookPixelArchiproId,
    facebookPixelClientId,
    pinterestTagArchiproId,
    pinterestTagClientId,
    snitcherTrackingId,
}: ClientConfig['pixels']) => {
    return (
        <>
            {snitcherTrackingId && <SnitcherBody id={snitcherTrackingId} />}
            <GTMBody id={gtmId} />
            <FacebookPixelsBody
                pixel={facebookPixelArchiproId}
                pixelClient={facebookPixelClientId}
            />
            <PinterestTrackerBody
                pixelArchipro={pinterestTagArchiproId}
                pixelClient={pinterestTagClientId}
            />
        </>
    );
};
