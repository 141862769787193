import {
    REFERRAL_ATTR_ITEM_ID,
    REFERRAL_ATTR_PROFESSIONAL_TITLE,
    TRACK_REFERRAL_CLASSNAME,
    type Tracker,
} from '@archipro-website/tracker';
import { useAppData } from '~/modules/root';
import { useIsGoodBot } from '~/modules/root/hook/use-is-good-bot';
import { decodeEntities } from '~/utils';
import { goInCurrentTab, goInNewTab } from '~/utils/urlHelper';

export interface WebsiteTrackingProps {
    websiteURL?: string | null;
    libraryItemID?: number;
    professionalID?: number;
    professionalTitle?: string | null;
    extraData?: unknown;
}

// If we start linking to client sites, we need to update the tracking to not use this function
// since it doesn't support tracking on right or middle mouse button clicks
export const onAdClickVisitWebsite = (
    trackingProps: WebsiteTrackingProps,
    tracker: Tracker,
    openInNewTab = true
): void => {
    const {
        websiteURL,
        libraryItemID = 0,
        professionalID = 0,
        professionalTitle = '',
        extraData,
    } = trackingProps;

    const website = getWebsiteLink(websiteURL);

    if (!website) {
        return;
    }

    const customData = {
        LibraryItemID: libraryItemID,
        ProfessionalID: professionalID,
        ProfessionalTitle: professionalTitle,
        ExternalURL: decodeEntities(website),
        extraData: extraData,
    };

    tracker.log('Website', {
        data: customData,
        ga4Data: customData,
        label: professionalTitle,
        url: new URL(window.location.href),
    });

    if (openInNewTab) {
        goInNewTab(website);
    } else {
        goInCurrentTab(website);
    }
};

// Can be removed if/once the above function is removed
const getWebsiteLink = (website?: string | null): string | null => {
    if (!website) {
        return null;
    }

    try {
        const websiteURL = decodeEntities(website);

        return enhanceProfessionalWebsiteLink(
            websiteURL,
            shouldAttachUTM(websiteURL)
        );
    } catch (_e) {
        console.error('Invalid URL');
        return null;
    }
};

export const useWebsiteReferralTracking = (
    websiteURL: string | undefined,
    inputParams: {
        libraryItemID: number;
        professionalTitle: string;
    }
): {
    referralTrackingURL: string | undefined;
    referralTrackingClass: string | undefined;
    referralTrackingParams:
        | {
              [REFERRAL_ATTR_ITEM_ID]: number;
              [REFERRAL_ATTR_PROFESSIONAL_TITLE]: string;
              rel: 'noopener';
          }
        | {};
} => {
    const selfIdentifiesAsBot = useIsGoodBot();
    const appData = useAppData();

    const userTrackingId = appData.user?.APID ?? null;
    const attachUserTrackingId = !selfIdentifiesAsBot && userTrackingId;

    if (!websiteURL) {
        console.warn('Website url not passed in!');
        return {
            referralTrackingURL: undefined,
            referralTrackingClass: undefined,
            referralTrackingParams: {},
        };
    }

    const trackedWebsite = enhanceProfessionalWebsiteLink(
        websiteURL,
        shouldAttachUTM(websiteURL),
        attachUserTrackingId ? userTrackingId : null
    );

    return {
        referralTrackingURL: trackedWebsite,
        referralTrackingClass: TRACK_REFERRAL_CLASSNAME,
        referralTrackingParams: {
            [REFERRAL_ATTR_ITEM_ID]: inputParams.libraryItemID,
            [REFERRAL_ATTR_PROFESSIONAL_TITLE]: inputParams.professionalTitle,
            rel: 'noopener',
        },
    };
};

/**
 * this function should be used everywhere to add needed
 * data when linking to a professional website.
 */
export const enhanceProfessionalWebsiteLink = (
    link: string,
    attachUTM: boolean,
    userTrackingId: string | null = null
): string => {
    try {
        let url = new URL(link);
        if (attachUTM) url = addUtmData(url);
        if (userTrackingId) url = addApIdTracking(url, userTrackingId);
        return url.href;
    } catch (_e) {
        return link;
    }
};

const addApIdTracking = (url: URL, userTrackingId: string): URL => {
    if (shouldTrackUrl(url)) {
        url.searchParams.append('__apid', userTrackingId);
    }

    return url;
};

type UTMParam = 'utm_source' | 'utm_medium' | 'utm_campaign' | 'utm_content';

export const addUtmData = (
    url: URL,
    utmData: Record<UTMParam, string> = {
        utm_source: 'archipro',
        utm_medium: 'website',
        utm_campaign: 'website',
        utm_content: '',
    }
): URL => {
    Object.keys(utmData).forEach((key) => {
        const value = utmData[key as UTMParam];
        if (!url.searchParams.has(key) && value) {
            url.searchParams.append(key, value);
        }
    });
    return url;
};

/***
 * super hacky hotfix to stop __apid to be added for provista
 * since it breaks the destination website
 * @param link
 */
const shouldTrackUrl = (url: URL): boolean => {
    const blacklist = [
        'clivebarrington.com',
        'www.fgrarchitects.com.au',
        'saniflo.co.nz',
        'www.saniflo.co.nz',
    ];

    return !blacklist.includes(url.host);
};

/**
 * super hax for blacklisting clients sites that
 * cannot handle query paramaters at all.
 */
const shouldAttachUTM = (url: string): boolean => {
    const blacklist = ['https://clivebarrington.com/'];

    return !blacklist.includes(url);
};
