/* eslint-disable jsx-a11y/alt-text */

interface PinterestTrackerProps {
    pixelArchipro: string;
    pixelClient: string;
}

export const PinterestTrackerHead = ({
    pixelArchipro,
    pixelClient,
}: PinterestTrackerProps): JSX.Element => {
    const html = `
        !function(e){if(!window.pintrk){window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
        n=window.pintrk;n.queue=[],n.version="3.0";var
        t=document.createElement("script");t.async=!0,t.src=e;var
        r=document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
        pintrk('load', '${pixelArchipro}');
        pintrk('load', '${pixelClient}');
        pintrk('page');
`;
    return (
        <script
            suppressHydrationWarning
            defer
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    );
};

export const PinterestTrackerBody = ({
    pixelArchipro,
    pixelClient,
}: PinterestTrackerProps): JSX.Element => {
    return (
        <noscript>
            <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                alt=""
                src={`https://ct.pinterest.com/v3/?event=init&tid=${pixelArchipro}&noscript=1`}
            />
            <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                alt=""
                src={`https://ct.pinterest.com/v3/?event=init&tid=${pixelClient}&noscript=1`}
            />
        </noscript>
    );
};
